//react
import React, { useContext, useEffect, useState } from "react";
//posthog
import { usePostHog } from 'posthog-js/react';
//next.js
import { useRouter } from "next/router";
import NextLink from "next/link";
//generate-password
import generator from 'generate-password';
//mui components
import { useTheme } from '@mui/styles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
//sqlitecloud lib
import { validateString, validateStringnonEncodingCharsInUrl } from "@lib/utils";
//sqlitecloud custmom hooks
import { useGetRegions, useEditData } from "@custom-hooks/useApi";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
import { useCopyToClipboard } from "@custom-hooks/useCopyToClipboard";
//sqlitecloud components
import Card from "@generic-components/Card";
import ModalContent from "@generic-components/ModalContent";
import ModalInput from "@generic-components/ModalInput";
import ModalSelect from "@generic-components/ModalSelect";
import ModalIndicator from "@generic-components/ModalIndicator";
import ModalInputFilled from "@generic-components/ModalInputFilled";
import MegaphoneSVG from "@generic-components/SVG/MegaphoneSVG";
//components specific utils
import { environmentType } from "./utils";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;
const DEFAULT_REGION = process.env.NEXT_PUBLIC_USE_DEFAULT_REGION;

const CreateProject = ({ firstProject = false, callback }) => {
	//get posthog
	const posthog = usePostHog();
	//get theme
	const theme = useTheme();
	//get method to call editing api
	const editDataOpt = {
		errorCallback: callback,
		mutatedCallback: callback,
		editedCallback: (createResult) => {
			//capture with posthog project creation event
			posthog.capture('project_create', {
				project: {
					firstProject: firstProject,
					id: createResult.projects[0].id,
					region: region,
					name: createResult.projects[0].name,
					description: createResult.projects[0].description,
				}
			})
			setActualProjectIndex(0);
			const newProjectId = createResult.projects[0].id;
			const NewProject = ({ projectId, callback }) => {
				const router = useRouter();
				console.log(router);
				useEffect(() => {
					const asPath = router.asPath;
					if (asPath === `/projects/${projectId}/nodes`) {
						callback();
					}
				}, [router])
				return (
					<div>
						<Typography variant="14px-med">You just made a New Project.</Typography><br />
						<NextLink href={`/projects/${projectId}/nodes?new_project=true`} passHref>
								<a onClick={callback} style={{ cursor: "pointer" }} className="unstyled-link">
								<Typography variant="14px-reg" sx={{ color: theme.palette.secondary.main, textDecoration: "underline" }}>Configure your project</Typography>
							</a>
						</NextLink>
					</div>
				)
			}
			const savedComponent = <NewProject projectId={newProjectId} />;
			createSnackNotification({
				type: "info-2",
				component: savedComponent
			});
		}
	};
	const { loading, mutatingData, editedData, error, editData } = useEditData(editDataOpt);
	//read state context
	const { setActualProjectIndex } = useContext(StateContext)
	//get method to update snack notification
	const { createSnackNotification } = useSetSnackNotification();
	//get method to copy to clipboard
	const { copyToClipboard } = useCopyToClipboard();
	//handle project parameters configuration
	const [projectName, setProjectName] = useState("my-project");
	const [projectNameValidation, setProjectNameValidation] = useState({ valid: true, messages: [""] });
	const [passwordValidation, setPasswordValidation] = useState({ valid: true, messages: [""] });
	const [adminName, setAdminName] = useState("admin");
	const [adminNameValidation, setAdminNameValidation] = useState({ valid: true, messages: [""] });
	const [password, setPassword] = useState(
		generator.generate({
			length: 10,
			numbers: true,
			excludeSimilarCharacters: true
		})
	);
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickGeneratePassword = () => {
		var passwordStrong = generator.generate({
			length: 10,
			numbers: true,
			excludeSimilarCharacters: true
		});
		setPassword(passwordStrong);
		setShowPassword(true);
		const validation = validateStringnonEncodingCharsInUrl(passwordStrong, undefined, false)
		setPasswordValidation(validation);
	}
	const getRegions = useGetRegions();
	const [regionValues, setRegionValues] = useState([]);
	useEffect(() => {
		if (getRegions.regions) {
			setRegionValues(getRegions.regions);
			setRegion(DEFAULT_REGION);
		}
	}, [getRegions.regions])
	const [region, setRegion] = useState("");
	const handleChangeRegion = (event) => {
		setRegion(event.target.value);
	}
	const [canCreate, setCanCreate] = useState(false);
	const handleChangeProjectName = (event) => {
		setProjectName(event.target.value);
	}
	const handleChangeAdminName = (event) => {
		setAdminName(event.target.value);
	}
	const handleChangePassword = (event) => {
		const newPassword = event.target.value;
		setPassword(newPassword);
		const validation = validateStringnonEncodingCharsInUrl(newPassword, undefined, false)
		setPasswordValidation(validation);
	}
	const [environment, setEnvironment] = useState(0);
	const handleChangeEnvironment = (event) => {
		setEnvironment(event.target.value);
	}
	useEffect(() => {
		let validateProjectName = { valid: false };
		if (projectName !== "") {
			validateProjectName = validateString(projectName);
			setProjectNameValidation(validateProjectName);
		}
		let validateAdminName = { valid: false };
		if (adminName !== "") {
			validateAdminName = validateString(adminName);
			setAdminNameValidation(validateAdminName);
		}
		if (
			projectName !== "" &&
			validateProjectName.valid &&
			adminName !== "" &&
			validateAdminName.valid &&
			password !== "" &&
			passwordValidation.valid &&
			region !== "" &&
			environment !== ""
		) {
			setCanCreate(true);
		} else {
			setCanCreate(false);
		}
	}, [projectName, adminName, password, region, passwordValidation, environment]);
	//method call to create project
	const create = async (e) => {
		e.preventDefault();
		if (UPGRADING_INFRA && UPGRADING_INFRA.toLocaleLowerCase() === "true") {
			alert("We're upgrading our infrastructure to serve you better! Creating a new project is temporarily disabled.")
		} else {
			let body = {
				"name": projectName,
				"region": region,
				"admin_username": adminName,
				"admin_password": password,
				"env": environment,
				"description": "My awesome project"
			};
			const opt = {
				method: "POST",
				endpoint: `/api/projects/create`,
				endpointCallLocation: "CreateProject.js",
				body: body,
				mutateApis: [
					["/api/projects", "useGetProjects"]
				]
			}
			await editData(opt);
		}
	};
	//render condition
	const showLoader = loading || mutatingData;
	const showForm = !showLoader && !error && !editedData;
	//render
	return (
		<ModalContent
			actionType="edit"
			title={"Configure your Project"}
			subtitle={"You're Deploying a Hobby Tier"}
			contactSupportModalType="upgrade"
			contact={true}
			showLoader={showLoader}
			editButtonLabel={"Deploy"}
			disabled={!canCreate || showLoader || error}
			runCallback={create}
		>
			{
				showForm &&
				<Card customSx={!firstProject ? { padding: 0 } : { padding: "1rem" }} type={firstProject ? "info-2" : "info-0"}>
					<form noValidate autoComplete="off">
						<Grid container flexDirection={"column"} rowGap={"1.125rem"}>
							{
								firstProject &&
								<>
									<Grid item>
										<MegaphoneSVG />
									</Grid>
									<Grid item>
										<Typography as="div" variant="17px-med" align="left" sx={{ overflowWrap: "break-word" }} >
											{projectName ? projectName : "..."}
										</Typography>
									</Grid>
								</>
							}
							<Grid item>
								<Grid container flexDirection={"column"}>
									<ModalInput
										type="text"
										label="Name"
										enableMinWidth={true}
										minWidth={"11.625rem"}
										flexGrow={0}
										customSxSubtext={{ maxWidth: "10rem", color: theme.palette.error.main }}
										error={!projectNameValidation.valid}
										subtext={projectNameValidation.messages[0]}
										value={projectName}
										onChange={handleChangeProjectName}
									/>
									<ModalSelect
										label="Region"
										enableMinWidth={true}
										minWidth={"11.625rem"}
										flexGrow={0}
										value={region}
										onChange={handleChangeRegion}
									>
										{
											regionValues.map((region, i) => {
												return (
													<MenuItem key={region.code} value={region.code}>{region.description}</MenuItem>
												)
											})
										}
									</ModalSelect>
									<ModalSelect
										label="Environment"
										enableMinWidth={true}
										minWidth={"11.625rem"}
										flexGrow={0}
										value={environment}
										onChange={handleChangeEnvironment}
									>
										{
											environmentType.map((environment, i) => {
												return (
													<MenuItem key={environment.value} value={environment.value}>{environment.label}</MenuItem>
												)
											})
										}
									</ModalSelect>
									<ModalIndicator
										label="Storage"
										height="1.125rem"
										info="1GB"
									/>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container flexDirection={"column"} rowGap={"0.5rem"}>
									<ModalInputFilled
										type={"text"}
										label={"Admin name:"}
										value={adminName}
										onChange={handleChangeAdminName}
										helperText={adminNameValidation.messages[0]}
										error={!adminNameValidation.valid}
									/>
									<ModalInputFilled
										type={showPassword ? "text" : "password"}
										label={"Password:"}
										value={password}
										onChange={handleChangePassword}
										helperText={passwordValidation.messages.join(" - ")}
										error={!passwordValidation.valid}
										endAdornment={
											<InputAdornment position="end" >
												<IconButton
													onClick={handleClickGeneratePassword}
													edge="end"
													sx={{ mr: 2, p: 0 }}
												>
													<KeyIcon sx={{ fontSize: "1rem" }} />
												</IconButton>
												<IconButton
													onClick={handleClickShowPassword}
													edge="end"
													sx={{ mr: 2, p: 0 }}
												>
													{showPassword ? <VisibilityOff sx={{ fontSize: "1rem" }} /> : <Visibility sx={{ fontSize: "1rem" }} />}
												</IconButton>
												<IconButton
													onClick={() => { copyToClipboard(password) }}
													edge="end"
													sx={{ mr: 0, p: 0 }}
												>
													<ContentCopyIcon sx={{ fontSize: "1rem" }} />
												</IconButton >
											</InputAdornment>
										}
									/>
								</Grid>
							</Grid>
							<Grid item sx={{ textAlign: "center" }} px={4}>
								<Typography variant="11px-med" sx={{ fontFamily: 'Roboto Mono', color: theme.palette.secondary.accent2 }} >
									Save this password to access your project later
								</Typography>
							</Grid>
						</Grid>
					</form>
				</Card>
			}
		</ModalContent>
	);
}

export default CreateProject;
